<template>
    <section>
        <!-- 选择栏 -->
        <el-col :span="24" class="toolbar" style="padding-bottom: 0; padding-left: 5px;">
            <el-form :inline="true" :model="filters" size="small">
                <el-form-item label="推广账号" prop="advAccountName" >
                    <el-select size="small" v-model="filters.advAccountName" value="" style="width:140px" filterable clearable>
                        <el-option v-for="item in bindAdvAccountOptions" :key="item.value" :value="item.value"
                            :label="item.label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="客户账号" prop="guestAccountId" v-if="!gconfig.isGuest()">
                    <el-select size="small" v-model.number="filters.guestAccountId" value="" style="width:140px" filterable clearable>
                        <el-option v-for="item in guestAccountOptions" :key="item.value" :value="item.value"
                            :label="item.label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="运营账号" prop="staffAccountId" v-if="gconfig.isAdmin()" >
                    <el-select size="small" v-model.number="filters.staffAccountId" value="" style="width:140px" filterable clearable>
                        <el-option v-for="item in staffAccountOptions" :key="item.value" :value="item.value"
                            :label="item.label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="平台" prop="platformId" >
                    <el-select size="small" v-model="filters.platformId" value="" style="width:140px" filterable clearable>
                        <el-option v-for="item in platformOptions" :key="item.value" :value="item.value"
                            :label="item.label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="域名" prop="domainName" >
                    <el-input size="small" v-model="filters.domainName" clearable style="width:140px"></el-input>
                </el-form-item>
                <el-form-item label="日期" >
                    <el-date-picker size="small" v-model="filters.timeRange" type="daterange" value-format="timestamp"
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width:250px">
                    </el-date-picker>
                </el-form-item>
                <el-form-item >
                    <el-button type="primary" size="small" v-on:click="handleList()" style="width:50px">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <export-file @download="handleExport" style="width:150px" />
                </el-form-item>
            </el-form>
        </el-col>

        <!-- 列表 -->
        <template>
            <el-table :data="dataList" stripe highlight-current-row v-loading="dataLoading" size="small" height="800"
                style="width: 100%;">
                <!-- <el-table-column prop="date" label="日期" width="100"></el-table-column> -->
                <el-table-column prop="adv_account" label="推广账号" sortable width="150">
                    <template slot-scope="scope">
                        <span v-if="scope.row.adv_account== ''">未绑定</span>
                        <span v-else style="color:green; font-weight: bold;" >{{ scope.row.adv_account }} </span>
                    </template>
                </el-table-column>
                <el-table-column v-if="!gconfig.isGuest()" prop="guest_account" label="客户账号" sortable width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.guest_account == ''">未绑定</span>
                        <span v-else style="color:green; font-weight: bold;" >{{ scope.row.guest_account }} </span>
                    </template>
                </el-table-column>
                <el-table-column v-if="gconfig.isAdmin()" prop="staff_account" label="运营账号" sortable width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.staff_account == ''">未绑定</span>
                        <span v-else style="color:orange; font-weight: bold;"> {{ scope.row.staff_account }} </span>
                    </template>
                </el-table-column>
                <el-table-column prop="adv_cost_total" label="消耗金额" width="100" sortable :sort-method="(a,b)=>{return a.adv_cost_total-b.adv_cost_total}"></el-table-column>
                <el-table-column prop="income_line" label="进线数量" width="100" sortable :sort-method="(a,b)=>{return a.income_line-b.income_line}"></el-table-column>
                <el-table-column prop="income_price" label="进线成本" width="100" sortable :sort-method="(a,b)=>{return a.income_price-b.income_price}"></el-table-column>
                <el-table-column prop="cash_cost" label="现金消耗" width="100" sortable :sort-method="(a,b)=>{return a.cash_cost-b.cash_cost}"></el-table-column>
                <el-table-column prop="cash_price" label="现金成本" width="100" sortable :sort-method="(a,b)=>{return a.cash_price-b.cash_price}"></el-table-column>
                <el-table-column prop="deal_num" label="成交数量" width="100" sortable :sort-method="(a,b)=>{return a.deal_num-b.deal_num}"></el-table-column>
                <el-table-column prop="deal_rate" label="成交率" width="100" sortable :sort-method="(a,b)=>{return a.deal_rate-b.deal_rate}">
                    <template slot-scope="scope">
                        {{ scope.row.deal_rate }}%
                    </template>
                </el-table-column>
                <el-table-column v-if="!gconfig.isGuest()" prop="check_std" label="考核标准" width="100" sortable :sort-method="(a,b)=>{return a.check_std-b.check_std}"></el-table-column>
                <el-table-column v-if="!gconfig.isGuest()" prop="invalid_num" label="无效资料数量" width="120" sortable :sort-method="(a,b)=>{return a.invalid_num-b.invalid_num}"></el-table-column>
                <el-table-column v-if="!gconfig.isGuest()" prop="invalid_rate" label="无效资料占比" width="120" sortable :sort-method="(a,b)=>{return a.invalid_rate-b.invalid_rate}">
                    <template slot-scope="scope">
                        {{ scope.row.invalid_rate }}%
                    </template>
                </el-table-column>
                <el-table-column prop="adv_balance_total" label="账号余额" width="150" sortable :sort-method="(a,b)=>{return a.adv_balance_total-b.adv_balance_total}"></el-table-column>
                <el-table-column prop="platform" label="平台" width="100" :formatter="formatPlatform"></el-table-column>
                <el-table-column prop="domain" label="域名" width="150"></el-table-column>

                <el-table-column label="操作" min-width="240" fixed="right">
                    <template slot-scope="scope">
                        <el-button size="small" v-if="!gconfig.isGuest()&&scope.row['adv_account']!='合计'" @click="handleHide(scope.row)"
                            :style="'width: 50px; margin-left:3px;font-weight:bold;' + (scope.row.hide == 0 ? 'color:green' : 'color:gray')">隐藏</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 翻页 -->
            <el-col :span="24" class="toolbar">
                <el-pagination layout="total, sizes, prev, pager, next" :current-page="this.page"
                    :total="total"
                    :page-size="pageSize" 
                    @current-change="handleCurrentChange" 
                    @size-change="handleSizeChange"
                    :page-sizes="[100,200,400,800]"
                    style="float:right;">
                </el-pagination>
            </el-col> 
        </template>
    </section>
</template>

<script>
import util from "../../common/js/util";
import gconfig from "../../api/gconfig";
import model from "../../common/js/model";
import { postRequest, formatTimestamp, getDotTime, dateFormat2 } from "../../api/api";
import { export_json_to_excel as Export2Excel } from '@/common/js/export2Excel'
import { filterRuleType, formatJSON, mapGenerator } from '@/common/js/formatJSON'

export default {
    data() {
        return {
            filters: {
                advAccountName: "",
                staffAccountId: "",
                guestAccountId: "",
                platformId: "", 
                domainName: "",
                timeRange: [getDotTime() * 1000, getDotTime() * 1000],
            },

         
            total: 0,
            page: 1,
            pageSize: 100,
            dataList: [],
            dataLoading: false,

            platformOptions: [],
            // bindDomainOptions: [],
            bindAdvAccountOptions: [],
            guestAccountOptions: [],
            staffAccountOptions: [],

            gconfig: gconfig,
        }
    },

    methods: {
        formatTime(row, column, cellValue) {
            if (cellValue == 0) {
                return "0000-00-00 00:00:00"
            }
            return formatTimestamp(cellValue);
        },
        formatPlatform(row, column, cellValue) {
            for (let item of this.platformOptions) {
                if (item.value == cellValue) {
                    return item.label;
                }
            }
            return cellValue;
        },

        handleList() {
            let params = Object.assign({}, this.filters);
            params.page = this.page,
            params.pageSize = this.pageSize,

            this.dataLoading = true;
            let queryModel = model.GetQueryModel("DataReportCtrl", "GetLineReport", params);
            postRequest(queryModel).then(res => {
                this.dataLoading = false;

                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                if (this.page === 1) {
                    this.total = res.data.total;
                }

                this.dataList = res.data.datas || [];
                let sum = {
                    "adv_account": "合计", 
                    "staff_account": "-", 
                    "guest_account": "-", 
                    "date": "-", 
                    "platform": "-", 
                    "domain": "-", 
                    "adv_info_cost": 0, 
                    "adv_search_cost": 0, 
                    "adv_info_balance": 0, 
                    "adv_search_balance": 0,
                    "income_line": 0, 
                    "deal_num": 0, 
                    "adv_cost_total": 0, 
                    "adv_balance_total": 0, 
                    "income_price": "0.00", 
                    "cash_cost": 0,
                    "cash_price": "0.00",
                    "deal_rate": "0.00",
                    "check_std": "0.00",
                    "invalid_num": 0,
                    "invalid_rate": "0.00"
                }
                for(let val of this.dataList) {
                    sum.income_line += val.income_line;
                    sum.deal_num += val.deal_num;
                    sum.adv_cost_total += val.adv_cost_total;
                    sum.adv_balance_total += val.adv_balance_total;
                    //现金消耗
                    sum.cash_cost += val.cash_cost;

                    val.adv_cost_total = val.adv_cost_total.toFixed(2);
                    val.adv_balance_total = val.adv_balance_total.toFixed(2);
                    val.cash_cost = val.cash_cost.toFixed(2);
                    //无效资数
                    sum.invalid_num +=  val.invalid_num;
                }

                //进线成本
                let income_price = sum.adv_cost_total / Math.max(1, sum.income_line)
                //成交率
                let deal_rate = sum.deal_num / Math.max(1, sum.income_line);

                //现金成本
                let cash_price = sum.cash_cost / Math.max(1, sum.income_line);
                sum.cash_price = cash_price.toFixed(2);

                sum.check_std = (deal_rate / Math.max(1, cash_price) * 10000).toFixed(2);

                sum.income_price = income_price.toFixed(2);
                sum.deal_rate = (deal_rate * 100).toFixed(2);
                sum.cash_cost = sum.cash_cost.toFixed(2);
                sum.adv_cost_total = sum.adv_cost_total.toFixed(2);
                sum.adv_balance_total = sum.adv_balance_total.toFixed(2);

                //无效比例
                sum.invalid_rate = (sum.invalid_num / Math.max(1, sum.income_line) * 100).toFixed(2);

                this.dataList.push(sum);

                console.log("handleList", this.dataList);

            });
        },

        handleCurrentChange(page) {
            this.page = page;
            this.handleList();
        },

        handleSizeChange(val) {
            this.pageSize = val;
            this.handleList();
        },

        handleBindAdvAccountList() {
            let params = {}
            let queryModel = model.GetQueryModel("AdvAccountCtrl", "GetBindAdvAccountList", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                for (let val of res.data.datas) {
                    this.bindAdvAccountOptions.push({label:val.uc_name, value:val.uc_name});
                }
                console.log("handleBindAdvAccountList", this.bindAdvAccountOptions);

            });
        },

        handleGuestAccountList() {
            let params = {};
            let queryModel = model.GetQueryModel("AccountMgrCtrl", "GetGuestAccountList", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                for (let val of res.data.datas) {
                    this.guestAccountOptions.push({label:val.name, value:val.id});
                }
                console.log("handleGuestAccountList", this.guestAccountOptions);

            });
        },

        handleStaffAccountList() {
            let params = {};
            let queryModel = model.GetQueryModel("AccountMgrCtrl", "GetStaffAccountList", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                for (let val of res.data.datas) {
                    this.staffAccountOptions.push({label:val.name, value:val.id});
                }
                console.log("handleStaffAccountList", this.staffAccountOptions);

            });
        },

        handleExport(exportData) {
            let headers = [
                '推广帐号',
                '客户帐号',
                '运营帐号',
                '消耗金额',
                '进线数量',
                '进线成本',
                '现金消耗',
                '现金成本',
                '成交数量',
                '成交率',
                '考核标准',
                '帐号余额',
                '平台',
                '域名',
            ]
            let self = this;
            let filterRule = [
                'adv_account',
                {
                    filterVal: 'guest_account',
                    convert: filterRuleType.TIME,
                    fn: val => function(val) { if (gconfig.isGuest()) { return "-"} return val; }(val),
                },
                {
                    filterVal: 'staff_account',
                    convert: filterRuleType.TIME,
                    fn: val => function(val) { if (gconfig.isStaff()) { return "-"} return val}(val),
                },
                'adv_cost_total',
                'income_line',
                'income_price',
                'cash_cost',
                'cash_price',
                'deal_num',
                'deal_rate',
                {
                    filterVal: 'check_std',
                    convert: filterRuleType.TIME,
                    fn: val => function(val) { if (gconfig.isGuest()) { return "-"} return val}(val),
                },
                'adv_balance_total',
                {
                    filterVal: 'platform',
                    convert: filterRuleType.TIME,
                    fn: val => self.formatPlatform(null, null, val),
                },
                'domain',
            ]
            let datas = formatJSON(filterRule, this.dataList)
            Export2Excel({
                header: headers,
                data: datas,
                filename: exportData.fileName || 'linereport_export_' + dateFormat2(null)
            })
        },
        handleHide(row) {
            if (row.adv_account == "") {
                this.$message.warning("未绑定推广帐号不能隐藏");
                return
            }
            let params = {adv_account: row.adv_account, hide: row.hide==0 ? 1 : 0};
            let queryModel = model.GetQueryModel("DataReportCtrl", "HandleHide", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                this.handleList();
            });
        },
    },

    mounted() {
        this.dataLoading = true;
        util.getPlatformOptions(this).then(res => {
            this.dataLoading = false;
            this.platformOptions = res;

            this.handleBindAdvAccountList();
            this.handleGuestAccountList();
            this.handleStaffAccountList();
            this.handleList();
        });
    }
}
</script>
<style scoped>
</style>